import React, { useState } from "react";
import { navigate } from "gatsby";
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image";
import { IoLogInOutline } from "react-icons/io5";
import { GatsbyImage } from "gatsby-plugin-image";

import {
  isBrowser,
  handleLogin,
  getLoginDataForSite,
} from "../components/services/auth";
import * as styles from "./login.module.scss";
import { useMDRTLogo } from "../hooks/useMDRTLogo";

const Login = () => {
  const loginData = getLoginDataForSite();

  const mdrtLogo = useMDRTLogo().light.childImageSharp.default;

  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState(false);

  if (Object.keys(loginData).length === 0) {
    isBrowser() && navigate("/404");

    return null;
  }

  const { siteId, siteSlug } = loginData;
  const {
    heroBanner,
    loginLabel,
    incorrectPasswordError,
    companyLogo,
  } = loginData.data;

  const handleUpdate = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!handleLogin(siteId, password)) {
      setLoginError(true);
    } else {
      navigate(`/${siteSlug}`);
    }
  };

  const bgImage = convertToBgImage(heroBanner.gatsbyImageData)
  return (
    <main className={styles["login"]}>
      <BackgroundImage
        Tag="div"
        className={styles["login__background"]}
        style={{ position: "absolute" }}
        {...bgImage}
        backgroundColor={`#040e18`}
      />
      <GatsbyImage
        image={mdrtLogo}
        style={{ position: "fixed" }}
        className={styles["login__mdrtLogo"]} />
      <div className={styles["login__container"]}>
        <div className={styles["login__content"]}>
          <form
            method="post"
            onSubmit={(event) => {
              handleSubmit(event);
            }}
            className={styles["login__form"]}
          >
            {companyLogo && (
              <GatsbyImage image={companyLogo.gatsbyImageData} className={styles["login__logo"]} />
            )}
            <div className={styles["login__inputWrapper"]}>
              <label htmlFor="password" className={styles["login__label"]}>
                {loginLabel}
              </label>
              <input
                id="password"
                type="password"
                name="password"
                onChange={handleUpdate}
                className={styles["login__input"]}
                placeholder={loginLabel}
              />
              {loginError && (
                <div className={styles["login__errorMessage"]}>
                  {incorrectPasswordError}
                </div>
              )}
            </div>
            <button type="submit" className={styles["login__button"]}>
              <IoLogInOutline className={styles["login__buttonIcon"]} />
              {loginLabel}
            </button>
          </form>
        </div>
      </div>
    </main>
  );
};

export default Login;
